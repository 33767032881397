// Colors
$color-white: #ffffff;
$color-black: #0f0a0aff;

$color-background: #f5efedff;
$color-primary: #2292a4ff;
$color-secondary: #bdbf09ff;
$color-tertiary: #d96c06ff;

$color-text: $color-black;

// Layout
$gap-sm: 8px;
$gap: 24px;
$gap-xl: 48px;

$border-radius-s: 6px;
$border-radius-l: 16px;