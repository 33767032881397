.nav {
    ul {
        display: flex;
        margin: 0;
        padding: 0;
        align-items: center;

        li {
            list-style: none;
        }
    }

    a,
    .menu-icon {
        border-radius: $border-radius-s;
        color: $color-text;
        display: block;
        font-weight: bold;
        padding: 8px 16px;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .menu-icon {
        display: none;
    }

    @media screen and (max-width: 720px) {
        .menu-icon {
            display: block;
        }

        ul {
            display: none;
        }

        &.responsive {
            ul {
                background: $color-white;
                display: block;
                position: absolute;
                top: 95px;
                right: 0;
                width: 100%;
                text-align: center;
                -webkit-box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
                -moz-box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
                box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
            }
        }
    }
}

.card {
    border-radius: $border-radius-l;
    margin: $gap-xl auto;
    padding: $gap $gap-xl;
    width: fit-content;

    &.glass-light {
        background-color: rgba(255, 255, 255, .4);
    }
    &.glass-dark {
        background-color: rgba(0, 0, 0, .4);
    }
}

// call to action
.cta {
    text-align: center;

    .cta-message {
        margin: 0 0 $gap 0;
        font-size: 24px;
    }

    .call {
        font-size: 48px;

        strong {
            color: $color-primary;
        }
    }
}