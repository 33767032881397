.header {
    background: $color-white;
    position: sticky;
    top: 0;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
    -moz-box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
    box-shadow: 0px 0px 8px 0px rgba(222, 222, 222, 1);
    z-index: 100;

    .content {
        align-items: center;
        display: flex;
        justify-content: space-between;
        padding: 8px;
    }

    .logo {
        margin: 0 48px 0 0;
    }
}

section {
    &:nth-child(odd) {
        background: $color-background;
    }
}

.footer {
    background: $color-black;
    color: $color-white;

    .content {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: $gap-xl $gap;

        .logo {
            margin: 0 0 $gap-xl 0;
        }

        .column {
            p {
                margin: 0;

                &:first-child {
                    margin-bottom: $gap-xl;
                }
            }
        }

        @media screen and (max-width: 720px) {
            display: block;

            .column {
                margin-bottom: $gap;
            }
        }
    }
}

.content {
    max-width: 80ch;
    margin: 0 auto;
    padding: $gap-xl $gap;
}