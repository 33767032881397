#uvitani {
    background-image: url(../images/img-main.jpg);
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    min-height: 80vh;
display: flex;
align-items: center;
justify-content: center;
    // .cta {
    //     position: absolute;
    //     left: 50%;
    //     top: calc(50% + 124px);
    //     transform: translate(-50%, -100%);
    //     width: 100%;
    //     z-index: 0;
    // }

    h1 {
        font-size: 64px;
        text-align: center;
        line-height: 1.6;
        margin: 0 auto $gap-xl auto;
    }

    .call {
        margin: $gap-xl auto;
    }

    @media screen and (max-width: 936px) {
        h1 {
            line-height: 1.2;
        }
    }
}

#proc-fotovoltaika {
    h2 {
        text-align: center;
    }

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
        gap: $gap;
    }

    li {
        margin: 0;
        padding: 0;
        list-style: none;
        width: 200px;
        height: 200px;
        text-align: center;
        font-style: italic;
        text-transform: uppercase;
    }

    i {
        font-size: 56px;
        color: $color-primary;
        display: block;
        margin: $gap;
    }
}

#fotovoltaicka-elektrarna {
    .akumulace {
        display: flex;
        gap: $gap;
        flex-wrap: wrap;
        margin: $gap-xl auto;

        .col {
            box-sizing: border-box;
            background: rgba($color-primary, .3);
            flex: 1;
            padding: $gap;
            border-radius: $border-radius-l;
            position: relative;
            overflow: hidden;
        }

        h4 {
            margin: 0;
        }

        h4,
        p {
            z-index: 1;
            position: relative;
        }

        i {
            color: $color-white;
            position: absolute;
            bottom: $gap;
            left: $gap;
            font-size: 140px;
            z-index: 0;
            opacity: .6;
        }

        @media screen and (max-width: 720px) {
            .col {
                width: 100%;
                flex: 1 0 100%;
            }
        }
    }

    .jak-funguje {
        h4 {
            margin: 0;
        }

        p {
            display: flex;
            align-items: center;
            margin-bottom: $gap-xl;
        }

        h4,
        p {
            max-width: 50%;
            margin-inline: auto;
        }

        i {
            font-size: 36px;
            color: $color-primary;
            margin-right: $gap;
        }

        @media screen and (max-width: 720px) {

            h4,
            p {
                max-width: 100%;
            }
        }
    }
}

#logo-spoluprace {
    padding: $gap-xl;
    display: flex;
    gap: $gap;
    justify-content: center;
}

#prubeh-spoluprace {
    h2 {
        text-align: center;
    }

    .step {
        border-radius: $border-radius-l;
        max-width: 50%;
        display: flex;

        .number {
            background: $color-primary;
            display: inline-block;
            border-radius: 50%;
            text-align: center;
            line-height: 80px;
            height: 80px;
            width: 80px;
            font-size: 2rem;
            color: $color-white;
            margin: 0 $gap 0 0;
            flex-shrink: 0;
        }

        .info {}

        &:nth-child(odd) {
            margin-left: auto;
        }
    }

    @media screen and (max-width: 720px) {
        .step {
            margin: $gap auto;
            max-width: 100%;
        }
    }

    @media screen and (max-width: 560px) {
        .step {
            margin: $gap 0;

            &:nth-child(odd) {
                margin-left: 0;
            }
        }
    }
}

#vyrizeni-dotace {}

#kontakt {}