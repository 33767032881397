body {
  color: #0f0a0a;
  font-variant: normal;
  min-width: 280px;
  margin: 0;
  padding: 0;
  font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 18px;
  font-style: normal;
}

h1 {
  margin: 48px 0 24px;
  font-size: 2.5rem;
  font-weight: 700;
  line-height: 1.2;
}

h2 {
  margin: 48px 0 24px;
  font-size: 2.2rem;
  font-weight: 600;
  line-height: 1.2;
}

h3 {
  margin: 48px 0 24px;
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.2;
}

h4 {
  margin: 48px 0 24px;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.2;
}

p, li {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.4;
}

p.long, li.long {
  text-align: justify;
}

a:visited {
  color: inherit;
  fill: #fff;
}

blockquote {
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.2;
}

pre {
  font-size: .8rem;
  font-weight: 400;
  line-height: 1.2;
}

.header {
  z-index: 100;
  background: #fff;
  position: sticky;
  top: 0;
  box-shadow: 0 0 8px #dedede;
}

.header .content {
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  display: flex;
}

.header .logo {
  margin: 0 48px 0 0;
}

section:nth-child(2n+1) {
  background: #f5efed;
}

.footer {
  color: #fff;
  background: #0f0a0a;
}

.footer .content {
  justify-content: space-between;
  align-items: flex-start;
  padding: 48px 24px;
  display: flex;
}

.footer .content .logo {
  margin: 0 0 48px;
}

.footer .content .column p {
  margin: 0;
}

.footer .content .column p:first-child {
  margin-bottom: 48px;
}

@media screen and (max-width: 720px) {
  .footer .content {
    display: block;
  }

  .footer .content .column {
    margin-bottom: 24px;
  }
}

.content {
  max-width: 80ch;
  margin: 0 auto;
  padding: 48px 24px;
}

.nav ul {
  align-items: center;
  margin: 0;
  padding: 0;
  display: flex;
}

.nav ul li {
  list-style: none;
}

.nav a, .nav .menu-icon {
  color: #0f0a0a;
  border-radius: 6px;
  padding: 8px 16px;
  font-weight: bold;
  text-decoration: none;
  display: block;
}

.nav a:hover, .nav .menu-icon:hover {
  text-decoration: underline;
}

.nav .menu-icon {
  display: none;
}

@media screen and (max-width: 720px) {
  .nav .menu-icon {
    display: block;
  }

  .nav ul {
    display: none;
  }

  .nav.responsive ul {
    width: 100%;
    text-align: center;
    background: #fff;
    display: block;
    position: absolute;
    top: 95px;
    right: 0;
    box-shadow: 0 0 8px #dedede;
  }
}

.card {
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 16px;
  margin: 48px auto;
  padding: 24px 48px;
}

.card.glass-light {
  background-color: #fff6;
}

.card.glass-dark {
  background-color: #0006;
}

.cta {
  text-align: center;
}

.cta .cta-message {
  margin: 0 0 24px;
  font-size: 24px;
}

.cta .call {
  font-size: 48px;
}

.cta .call strong {
  color: #2292a4;
}

#uvitani {
  min-height: 80vh;
  background-image: url("img-main.60b312b6.jpg");
  background-position: top;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  justify-content: center;
  align-items: center;
  display: flex;
}

#uvitani h1 {
  text-align: center;
  margin: 0 auto 48px;
  font-size: 64px;
  line-height: 1.6;
}

#uvitani .call {
  margin: 48px auto;
}

@media screen and (max-width: 936px) {
  #uvitani h1 {
    line-height: 1.2;
  }
}

#proc-fotovoltaika h2 {
  text-align: center;
}

#proc-fotovoltaika ul {
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  gap: 24px;
  margin: 0;
  padding: 0;
  display: flex;
}

#proc-fotovoltaika li {
  width: 200px;
  height: 200px;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-style: italic;
  list-style: none;
}

#proc-fotovoltaika i {
  color: #2292a4;
  margin: 24px;
  font-size: 56px;
  display: block;
}

#fotovoltaicka-elektrarna .akumulace {
  flex-wrap: wrap;
  gap: 24px;
  margin: 48px auto;
  display: flex;
}

#fotovoltaicka-elektrarna .akumulace .col {
  box-sizing: border-box;
  background: #2292a44d;
  border-radius: 16px;
  flex: 1;
  padding: 24px;
  position: relative;
  overflow: hidden;
}

#fotovoltaicka-elektrarna .akumulace h4 {
  margin: 0;
}

#fotovoltaicka-elektrarna .akumulace h4, #fotovoltaicka-elektrarna .akumulace p {
  z-index: 1;
  position: relative;
}

#fotovoltaicka-elektrarna .akumulace i {
  color: #fff;
  z-index: 0;
  opacity: .6;
  font-size: 140px;
  position: absolute;
  bottom: 24px;
  left: 24px;
}

@media screen and (max-width: 720px) {
  #fotovoltaicka-elektrarna .akumulace .col {
    width: 100%;
    flex: 1 0 100%;
  }
}

#fotovoltaicka-elektrarna .jak-funguje h4 {
  margin: 0;
}

#fotovoltaicka-elektrarna .jak-funguje p {
  align-items: center;
  margin-bottom: 48px;
  display: flex;
}

#fotovoltaicka-elektrarna .jak-funguje h4, #fotovoltaicka-elektrarna .jak-funguje p {
  max-width: 50%;
  margin-inline: auto;
}

#fotovoltaicka-elektrarna .jak-funguje i {
  color: #2292a4;
  margin-right: 24px;
  font-size: 36px;
}

@media screen and (max-width: 720px) {
  #fotovoltaicka-elektrarna .jak-funguje h4, #fotovoltaicka-elektrarna .jak-funguje p {
    max-width: 100%;
  }
}

#logo-spoluprace {
  justify-content: center;
  gap: 24px;
  padding: 48px;
  display: flex;
}

#prubeh-spoluprace h2 {
  text-align: center;
}

#prubeh-spoluprace .step {
  max-width: 50%;
  border-radius: 16px;
  display: flex;
}

#prubeh-spoluprace .step .number {
  text-align: center;
  height: 80px;
  width: 80px;
  color: #fff;
  background: #2292a4;
  border-radius: 50%;
  flex-shrink: 0;
  margin: 0 24px 0 0;
  font-size: 2rem;
  line-height: 80px;
  display: inline-block;
}

#prubeh-spoluprace .step:nth-child(2n+1) {
  margin-left: auto;
}

@media screen and (max-width: 720px) {
  #prubeh-spoluprace .step {
    max-width: 100%;
    margin: 24px auto;
  }
}

@media screen and (max-width: 560px) {
  #prubeh-spoluprace .step {
    margin: 24px 0;
  }

  #prubeh-spoluprace .step:nth-child(2n+1) {
    margin-left: 0;
  }
}

/*# sourceMappingURL=index.68fd339b.css.map */
