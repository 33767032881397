body {
    margin: 0;
    padding: 0;
    color: $color-text;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-style: normal;
    font-variant: normal;
    min-width: 280px;
    font-size: 18px;
}

h1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin: $gap-xl 0 $gap 0;
}

h2 {
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 1.2;
    margin: $gap-xl 0 $gap 0;
}

h3 {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1.2;
    margin: $gap-xl 0 $gap 0;
}

h4 {
    font-size: 1.4rem;
    font-weight: 400;
    line-height: 1.2;
    margin: $gap-xl 0 $gap 0;
}

p, li {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.4;

    &.long {
        text-align: justify;
    }
}

a {
    &:visited {
        color: inherit;
        fill: white;
    }
}

blockquote {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.2;
}

pre {
    font-size: .8rem;
    font-weight: 400;
    line-height: 1.2;
}